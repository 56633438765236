import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alerts = _resolveComponent("Alerts")!
  const _component_ViewTemplate = _resolveComponent("ViewTemplate")!

  return (_openBlock(), _createBlock(_component_ViewTemplate, { title: "Dashboard" }, {
    default: _withCtx(() => [
      _createVNode(_component_Alerts, {
        title: _ctx.tc.instid,
        priority: 4
      }, null, 8, ["title"])
    ]),
    _: 1
  }))
}