import httpClient from "./httpClient";
import { AxiosResponse } from "axios";
import EmdAddress from "@/model/emdAddress";

export default class EmdAddressService {
  static async myTc(): Promise<AxiosResponse<EmdAddress>> {
    return (await httpClient).get(`/my-tc`);
  }

  static async dnaLaboratory(): Promise<AxiosResponse<EmdAddress[]>> {
    return (await httpClient).get(`/dna-laboratory`);
  }
}
